<template>
  <div id="editEmployeePage">
    <General :loading="loading" :data="form" @saveForm="handleSaveForm" />

    <ConfirmationPopup
      id="employee-edit-leave-modal"
      title="Qeydə alınmamış məlumatlar"
      :confirmText="'Çıxış et'"
      :cancelText="'İmtina et'"
      v-if="confirmationDialog"
      :dialog="confirmationDialog"
      @confirm="handleLeaveConfirmation"
      @cancel="confirmationDialog = false"
    >
      <b>{{ `${this.form.firstName} ${this.form.lastName}` }}</b> haqqında dəyişilən məlumatlar
      yadda saxlanılmayacaq
    </ConfirmationPopup>
  </div>
</template>

<script>
import API from '@/services'
import generalEmployeeInfoMixin from '@/mixins/generalEmployeeInfo'
import bus from "../../../../../../event-bus";
import General from '../../../Shared/components/Form/General/index.vue'
import ConfirmationPopup from '@/shared/Common/ConfirmationPopup.vue'

export default {
  mixins: [generalEmployeeInfoMixin],
  components: {
    General,
    ConfirmationPopup
  },
  data() {
    return {
      loading: false,
      form: null
    }
  },
  methods: {
    handleSaveForm(form) {
      this.updateHREmployee(form)
    },
    async checkID() {
      if (this.$route.params.id && this.$route.params.id > 0) {
        this.form = await API.fetchHREmployee(this.$route.params.id)
        this.oldData = JSON.parse(JSON.stringify(this.form))

        if (!this.form.birthDate) return

        const date = new Date(this.form.birthDate)
        const formattedDate =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date
            .getDate()
            .toString()
            .padStart(2, '0')

        this.oldData.birthDate = formattedDate
      }
    },
    updateHREmployee(data) {
      this.loading = true
      API.updateHREmployee(data)
        .then(() => {
          this.oldData = {
            ...this.form
          }

          bus.$emit('notificationMessage', {
            color: 'success',
            text: 'Əməkdaş məlumatları yeniləndi.'
          })
        })
        .catch(() => {
          bus.$emit('notificationMessage', {
            color: 'error',
            text: 'Əməkdaş məlumatlarını yeniləmək mümkün olmadı.'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    this.checkID()
  }
}
</script>
<style></style>
